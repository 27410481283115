var render, staticRenderFns
import script from "./Checkbox.vue?vue&type=script&lang=js&"
export * from "./Checkbox.vue?vue&type=script&lang=js&"
import style0 from "./Checkbox.vue?vue&type=style&index=0&id=657642fb&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657642fb",
  null
  
)

export default component.exports