var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VBtn",
    _vm._b(
      {
        class: { truncate: _vm.truncate },
        style: { color: _vm.$vuetify.theme.primary },
        attrs: { target: _vm.target, flat: "", exact: "" },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.stopPropagation()
            return _vm.$emit("click")
          },
          click: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("click")
          },
        },
      },
      "VBtn",
      _vm.$attrs,
      false
    ),
    [
      _vm._t("default", function () {
        return [
          _c(
            "span",
            {
              class: {
                "text-truncate": _vm.truncate,
                notranslate: _vm.notranslate,
              },
              staticStyle: { "text-decoration": "underline" },
              attrs: { title: _vm.text },
            },
            [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]
          ),
          _vm.target === "_blank"
            ? _c(
                "Icon",
                { staticClass: "mx-1 rtl-flip", attrs: { small: "" } },
                [_vm._v("\n      open_in_new\n    ")]
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }