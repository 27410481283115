var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ImmersiveModalLayout",
    {
      attrs: {
        previousPage: { name: "Main" },
        appBarText: _vm.$tr("backToLoginButton"),
        backButton: "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "align-center d-flex justify-center mb-2" },
        [
          _c("KLogo", {
            attrs: {
              altText: "Kolibri Logo with background",
              showBackground: true,
              size: 120,
            },
          }),
        ],
        1
      ),
      _c(
        "h2",
        { ref: "top", staticClass: "mb-4 primary--text text-xs-center" },
        [_vm._v("\n    " + _vm._s(_vm.$tr("createAnAccountTitle")) + "\n  ")]
      ),
      _c(
        "VLayout",
        { staticClass: "px-3", attrs: { "justify-center": "" } },
        [
          _c(
            "VForm",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "Banner",
                {
                  staticClass: "mb-4",
                  attrs: { value: !_vm.valid, error: "" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.registrationFailed
                          ? _vm.$tr("registrationFailed")
                          : _vm.$tr("errorsMessage")
                      ) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "Banner",
                {
                  staticClass: "mb-4",
                  attrs: { value: _vm.offline, error: "" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$tr("registrationFailedOffline")) +
                      "\n      "
                  ),
                ]
              ),
              _c("h1", { staticClass: "font-weight-bold my-2 subheading" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$tr("basicInformationHeader")) +
                    "\n      "
                ),
              ]),
              _c("TextField", {
                attrs: {
                  maxlength: "100",
                  counter: "",
                  label: _vm.$tr("firstNameLabel"),
                  autofocus: "",
                },
                model: {
                  value: _vm.form.first_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "first_name", $$v)
                  },
                  expression: "form.first_name",
                },
              }),
              _c("TextField", {
                attrs: {
                  maxlength: "100",
                  counter: "",
                  label: _vm.$tr("lastNameLabel"),
                },
                model: {
                  value: _vm.form.last_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "last_name", $$v)
                  },
                  expression: "form.last_name",
                },
              }),
              _c("EmailField", {
                attrs: {
                  maxlength: "100",
                  counter: "",
                  disabled: Boolean(_vm.$route.query.email),
                  "error-messages": _vm.emailErrors,
                },
                on: {
                  input: function ($event) {
                    _vm.emailErrors = []
                  },
                },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
              _c("PasswordField", {
                attrs: { label: _vm.$tr("passwordLabel") },
                model: {
                  value: _vm.form.password1,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password1", $$v)
                  },
                  expression: "form.password1",
                },
              }),
              _c("PasswordField", {
                attrs: {
                  additionalRules: _vm.passwordConfirmRules,
                  label: _vm.$tr("confirmPasswordLabel"),
                },
                model: {
                  value: _vm.form.password2,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password2", $$v)
                  },
                  expression: "form.password2",
                },
              }),
              _c("VInput", {
                staticClass: "mt-2",
                attrs: { required: "", rules: _vm.usageRules },
              }),
              _c("h1", { staticClass: "font-weight-bold mb-2 subheading" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$tr("usageLabel")) + "*\n      "
                ),
              ]),
              _vm._l(_vm.usageOptions, function (option) {
                return _c(
                  "div",
                  { key: option.id },
                  [
                    _c("Checkbox", {
                      attrs: { label: option.label, value: option.id },
                      model: {
                        value: _vm.form.uses,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "uses", $$v)
                        },
                        expression: "form.uses",
                      },
                    }),
                    _c(
                      "VSlideYTransition",
                      [
                        _vm.showStorageField(option.id)
                          ? _c("TextField", {
                              staticClass: "ml-4 my-1",
                              attrs: {
                                label: _vm.$tr("storingUsagePlaceholder"),
                                placeholder: _vm.$tr("storingUsageExample"),
                              },
                              model: {
                                value: _vm.form.storage,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "storage", $$v)
                                },
                                expression: "form.storage",
                              },
                            })
                          : _vm.showOtherField(option.id)
                          ? _c("TextArea", {
                              staticClass: "ml-4 my-2",
                              attrs: {
                                label: _vm.$tr("otherUsagePlaceholder"),
                              },
                              model: {
                                value: _vm.form.other_use,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "other_use", $$v)
                                },
                                expression: "form.other_use",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c("VInput", {
                staticClass: "mt-4",
                attrs: { required: "", rules: _vm.locationRules },
              }),
              _c("h1", { staticClass: "font-weight-bold my-2 subheading" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$tr("locationLabel")) + "*\n      "
                ),
              ]),
              _c("CountryField", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.locations,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "locations", $$v)
                  },
                  expression: "form.locations",
                },
              }),
              _c("VInput", {
                staticClass: "mt-2",
                attrs: { required: "", rules: _vm.sourceRules },
              }),
              _c("h1", { staticClass: "font-weight-bold my-2 subheading" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$tr("sourceLabel")) + "*\n      "
                ),
              ]),
              _c("DropdownWrapper", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var attach = ref.attach
                      var menuProps = ref.menuProps
                      return [
                        _c("VSelect", {
                          attrs: {
                            items: _vm.sourceOptions,
                            "item-text": "label",
                            "item-value": "id",
                            box: "",
                            "menu-props": menuProps,
                            attach: attach,
                            label: _vm.$tr("sourcePlaceholder"),
                          },
                          model: {
                            value: _vm.form.source,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "source", $$v)
                            },
                            expression: "form.source",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "VSlideYTransition",
                [
                  _vm.form.source === _vm.sources.ORGANIZATION
                    ? _c("TextArea", {
                        attrs: {
                          label: _vm.$tr("organizationSourcePlaceholder"),
                        },
                        model: {
                          value: _vm.form.organization,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "organization", $$v)
                          },
                          expression: "form.organization",
                        },
                      })
                    : _vm.form.source === _vm.sources.CONFERENCE
                    ? _c("TextArea", {
                        attrs: {
                          label: _vm.$tr("conferenceSourcePlaceholder"),
                        },
                        model: {
                          value: _vm.form.conference,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "conference", $$v)
                          },
                          expression: "form.conference",
                        },
                      })
                    : _vm.form.source === _vm.sources.OTHER
                    ? _c("TextArea", {
                        attrs: { label: _vm.$tr("otherSourcePlaceholder") },
                        model: {
                          value: _vm.form.other_source,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "other_source", $$v)
                          },
                          expression: "form.other_source",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("Checkbox", {
                staticClass: "my-1 policy-checkbox",
                attrs: {
                  label: _vm.$tr("agreement"),
                  required: "",
                  rules: _vm.tosAndPolicyRules,
                  "hide-details": false,
                },
                model: {
                  value: _vm.acceptedAgreement,
                  callback: function ($$v) {
                    _vm.acceptedAgreement = $$v
                  },
                  expression: "acceptedAgreement",
                },
              }),
              _c("div", { staticClass: "span-spacing" }, [
                _c(
                  "span",
                  [
                    _c("ActionLink", {
                      attrs: { text: _vm.$tr("viewPrivacyPolicyLink") },
                      on: { click: _vm.showPrivacyPolicy },
                    }),
                  ],
                  1
                ),
                _c("span", [_vm._v(" | ")]),
                _c(
                  "span",
                  [
                    _c("ActionLink", {
                      attrs: { text: _vm.$tr("viewToSLink") },
                      on: { click: _vm.showTermsOfService },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "mt-2 span-spacing" }, [
                _c("div", { staticClass: "align-items" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tr("contactMessage")) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("KButton", {
                staticClass: "mt-5",
                attrs: {
                  primary: "",
                  disabled: _vm.offline,
                  text: _vm.$tr("finishButton"),
                  type: "submit",
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("PolicyModals"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }