var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PoliciesModal",
    {
      attrs: {
        policy: _vm.policy,
        title: _vm.title,
        needsAcceptance: _vm.needsAcceptance,
        "data-test": "privacy-modal",
      },
      on: {
        accept: function ($event) {
          return _vm.$emit("accept")
        },
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "privacy-wrapper", attrs: { dir: "auto" } }, [
        _c("p", { staticClass: "emphasis" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$untranslated("introductionHeader")) +
              "\n    "
          ),
        ]),
        _c(
          "p",
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$untranslated("introductionP1Part1")) +
                "\n      "
            ),
            _c("ActionLink", {
              attrs: { text: _vm.$untranslated("introductionP1TC") },
              on: {
                click: function ($event) {
                  return _vm.$emit("open", _vm.policies.TERMS_OF_SERVICE)
                },
              },
            }),
            _vm._v(
              "\n      " +
                _vm._s(_vm.$untranslated("introductionP1Part2")) +
                "\n      "
            ),
            _c("b", [_vm._v(_vm._s(_vm.$untranslated("introductionP1TU")))]),
            _vm._v(
              "\n      " +
                _vm._s(_vm.$untranslated("introductionP1Part3")) +
                "\n    "
            ),
          ],
          1
        ),
        _c("ul", [
          _c("li", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$untranslated("introductionItem1Part1")) +
                "\n        "
            ),
            _c("b", [
              _vm._v(_vm._s(_vm.$untranslated("introductionItem1Website"))),
            ]),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$untranslated("introductionItem1Part2")) +
                "\n      "
            ),
          ]),
          _c("li", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$untranslated("introductionItem2Part1")) +
                "\n        "
            ),
            _c("b", [
              _vm._v(_vm._s(_vm.$untranslated("introductionItem2Service"))),
            ]),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$untranslated("introductionItem2Part2")) +
                "\n      "
            ),
          ]),
        ]),
        _c("p", [_vm._v(_vm._s(_vm.$untranslated("introductionP2")))]),
        _c("p", [_vm._v(_vm._s(_vm.$untranslated("introductionP3")))]),
        _c("p", [_vm._v(_vm._s(_vm.$untranslated("introductionP4")))]),
        _c("ol", { staticClass: "mb-4 text-uppercase toc" }, [
          _c("li", [
            _c("a", { attrs: { href: "#important-info" } }, [
              _vm._v(_vm._s(_vm.$untranslated("importantInfoHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#data-collection" } }, [
              _vm._v(_vm._s(_vm.$untranslated("dataCollectionHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#personal-data-collection" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$untranslated("personalDataCollectionHeader")) +
                  "\n        "
              ),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#personal-data-use" } }, [
              _vm._v(_vm._s(_vm.$untranslated("personalDataUseHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#personal-data-disclosure" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$untranslated("personalDataDisclosureHeader")) +
                  "\n        "
              ),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#international-transfers" } }, [
              _vm._v(_vm._s(_vm.$untranslated("internationalTransfersHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#data-security" } }, [
              _vm._v(_vm._s(_vm.$untranslated("dataSecurityHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#data-retention" } }, [
              _vm._v(_vm._s(_vm.$untranslated("dataRetentionHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#expectation-of-privacy" } }, [
              _vm._v(_vm._s(_vm.$untranslated("expectationOfPrivacyHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#legal-rights" } }, [
              _vm._v(_vm._s(_vm.$untranslated("legalRightsHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#contact-us" } }, [
              _vm._v(_vm._s(_vm.$untranslated("contactUsHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#cookies" } }, [
              _vm._v(_vm._s(_vm.$untranslated("cookiesHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#glossary" } }, [
              _vm._v(_vm._s(_vm.$untranslated("glossaryHeader"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "important-info" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$untranslated("importantInfoHeader")))]),
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$untranslated("importantInfoP1Part1")) +
                "\n        "
            ),
            _c("b", [
              _vm._v(_vm._s(_vm.$untranslated("importantInfoP1Terms"))),
            ]),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$untranslated("importantInfoP1Part2")) +
                "\n      "
            ),
          ]),
          _c("p", [
            _c("b", [_vm._v(_vm._s(_vm.$untranslated("importantInfoP2")))]),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("importantInfoP3")))]),
          _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.$untranslated("importantInfoItem1")))]),
            _c("li", [_vm._v(_vm._s(_vm.$untranslated("importantInfoItem2")))]),
            _c("li", [_vm._v(_vm._s(_vm.$untranslated("importantInfoItem3")))]),
          ]),
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$untranslated("importantInfoP4Part1")) +
                "\n        "
            ),
            _c("b", [_vm._v(_vm._s(_vm.$untranslated("importantInfoP4ICO")))]),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$untranslated("importantInfoP4Part2")) +
                "\n      "
            ),
          ]),
          _c("p", [
            _c("b", [_vm._v(_vm._s(_vm.$untranslated("importantInfoP5")))]),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("importantInfoP6")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("importantInfoP7")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("importantInfoP8")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("importantInfoP9")))]),
          _c("p", [
            _c("b", [_vm._v(_vm._s(_vm.$untranslated("importantInfoP10")))]),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("importantInfoP11")))]),
        ]),
        _c(
          "div",
          { staticClass: "section", attrs: { id: "data-collection" } },
          [
            _c("h2", [
              _vm._v(_vm._s(_vm.$untranslated("dataCollectionHeader"))),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.$untranslated("dataCollectionP1")))]),
            _c("ul", [
              _c("li", [
                _vm._v(_vm._s(_vm.$untranslated("dataCollectionItem1"))),
              ]),
              _c("li", [
                _vm._v(_vm._s(_vm.$untranslated("dataCollectionItem2"))),
              ]),
              _c("li", [
                _vm._v(_vm._s(_vm.$untranslated("dataCollectionItem3"))),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "section", attrs: { id: "personal-data-collection" } },
          [
            _c("h2", [
              _vm._v(_vm._s(_vm.$untranslated("personalDataCollectionHeader"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$untranslated("personalDataCollectionP1"))),
            ]),
            _c("ul", [
              _c("li", [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.$untranslated("personalDataCollectionItem1Part1")
                    )
                  ),
                ]),
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$untranslated("personalDataCollectionItem1Part2")
                    ) +
                    "\n        "
                ),
              ]),
              _c("li", [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.$untranslated("personalDataCollectionItem2Part1")
                    )
                  ),
                ]),
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$untranslated("personalDataCollectionItem2Part2")
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "section", attrs: { id: "personal-data-use" } },
          [
            _c("h2", [
              _vm._v(_vm._s(_vm.$untranslated("personalDataUseHeader"))),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.$untranslated("personalDataUseP1")))]),
            _c("ul", [
              _c("li", [
                _vm._v(_vm._s(_vm.$untranslated("personalDataUseItem1"))),
              ]),
              _c("li", [
                _vm._v(_vm._s(_vm.$untranslated("personalDataUseItem2"))),
              ]),
              _c("li", [
                _vm._v(_vm._s(_vm.$untranslated("personalDataUseItem3"))),
              ]),
            ]),
            _c("p", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$untranslated("personalDataUseP2Part1")) +
                  "\n        "
              ),
              _c("em", [
                _vm._v(_vm._s(_vm.$untranslated("personalDataUseP2Glossary"))),
              ]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$untranslated("personalDataUseP2Part2")) +
                  "\n      "
              ),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.$untranslated("personalDataUseP3")))]),
            _c("ul", [
              _c("li", [
                _vm._v(_vm._s(_vm.$untranslated("personalDataUseItem4"))),
              ]),
              _c("li", [
                _vm._v(_vm._s(_vm.$untranslated("personalDataUseItem5"))),
              ]),
              _c("li", [
                _vm._v(_vm._s(_vm.$untranslated("personalDataUseItem6"))),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "section", attrs: { id: "personal-data-disclosure" } },
          [
            _c("h2", [
              _vm._v(_vm._s(_vm.$untranslated("personalDataDisclosureHeader"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$untranslated("personalDataDisclosureP1"))),
            ]),
            _c("ul", [
              _c("li", [
                _vm._v(
                  _vm._s(_vm.$untranslated("personalDataDisclosureItem1"))
                ),
              ]),
              _c("li", [
                _vm._v(
                  _vm._s(_vm.$untranslated("personalDataDisclosureItem2"))
                ),
              ]),
              _c("li", [
                _vm._v(
                  _vm._s(_vm.$untranslated("personalDataDisclosureItem3"))
                ),
              ]),
              _c("li", [
                _vm._v(
                  _vm._s(_vm.$untranslated("personalDataDisclosureItem4"))
                ),
              ]),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$untranslated("personalDataDisclosureP2"))),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "section", attrs: { id: "international-transfers" } },
          [
            _c("h2", [
              _vm._v(_vm._s(_vm.$untranslated("internationalTransfersHeader"))),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$untranslated("internationalTransfersP1"))),
            ]),
          ]
        ),
        _c("div", { staticClass: "section", attrs: { id: "data-security" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$untranslated("dataSecurityHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("dataSecurityP1")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("dataSecurityP2")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("dataSecurityP3")))]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "data-retention" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$untranslated("dataRetentionHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("dataRetentionP1")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("dataRetentionP2")))]),
        ]),
        _c(
          "div",
          { staticClass: "section", attrs: { id: "expectation-of-privacy" } },
          [
            _c("h2", [
              _vm._v(_vm._s(_vm.$untranslated("expectationOfPrivacyHeader"))),
            ]),
            _c(
              "p",
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$untranslated("expectationOfPrivacyP1")) +
                    "\n        "
                ),
                _c("ActionLink", {
                  attrs: {
                    text: _vm.$untranslated("expectationOfPrivacyP1TC"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("open", _vm.policies.TERMS_OF_SERVICE)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "section", attrs: { id: "legal-rights" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$untranslated("legalRightsHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("legalRightsP1")))]),
          _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.$untranslated("legalRightsItem1")))]),
            _c("li", [_vm._v(_vm._s(_vm.$untranslated("legalRightsItem2")))]),
            _c("li", [_vm._v(_vm._s(_vm.$untranslated("legalRightsItem3")))]),
            _c("li", [_vm._v(_vm._s(_vm.$untranslated("legalRightsItem4")))]),
            _c("li", [_vm._v(_vm._s(_vm.$untranslated("legalRightsItem5")))]),
            _c("li", [_vm._v(_vm._s(_vm.$untranslated("legalRightsItem6")))]),
            _c("li", [_vm._v(_vm._s(_vm.$untranslated("legalRightsItem7")))]),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("legalRightsP2")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("legalRightsP3")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("legalRightsP4")))]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "contact-us" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$untranslated("contactUsHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("contactUsP1")))]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "cookies" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$untranslated("cookiesHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("cookiesP1")))]),
          _c(
            "p",
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$untranslated("cookiesP2")) +
                  "\n        "
              ),
              _c("ActionLink", {
                attrs: {
                  text: _vm.$untranslated("cookieLink"),
                  href: "https://learningequality.org/cookies/",
                  target: "_blank",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "glossary" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$untranslated("glossaryHeader")))]),
          _c("p", [
            _c("b", [_vm._v(_vm._s(_vm.$untranslated("glossaryP1Consent")))]),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$untranslated("glossaryP1")) +
                "\n      "
            ),
          ]),
          _c("p", [
            _c("b", [
              _vm._v(_vm._s(_vm.$untranslated("glossaryP2LegitimateInterest"))),
            ]),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$untranslated("glossaryP2")) +
                "\n      "
            ),
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                _vm._s(_vm.$untranslated("glossaryP3PerformanceOfContract"))
              ),
            ]),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$untranslated("glossaryP3")) +
                "\n      "
            ),
          ]),
          _c("p", [
            _c("b", [_vm._v(_vm._s(_vm.$untranslated("glossaryP4Comply")))]),
            _vm._v(_vm._s(_vm.$untranslated("glossaryP4"))),
          ]),
          _c("p", [
            _c("b", [
              _vm._v(_vm._s(_vm.$untranslated("glossaryP5ThirdParty"))),
            ]),
            _vm._v(_vm._s(_vm.$untranslated("glossaryP5"))),
          ]),
          _c("p", [_c("b", [_vm._v(_vm._s(_vm.$untranslated("glossaryP6")))])]),
          _c("p", [_vm._v(_vm._s(_vm.$untranslated("glossaryP7")))]),
          _c("ul", [
            _c("li", [
              _c("b", [
                _vm._v(_vm._s(_vm.$untranslated("glossaryItem1RequestAccess"))),
              ]),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$untranslated("glossaryItem1")) +
                  "\n        "
              ),
            ]),
            _c("li", [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$untranslated("glossaryItem2RequestCorrection"))
                ),
              ]),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$untranslated("glossaryItem2")) +
                  "\n        "
              ),
            ]),
            _c("li", [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$untranslated("glossaryItem3RequestErasure"))
                ),
              ]),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$untranslated("glossaryItem3")) +
                  "\n        "
              ),
            ]),
            _c("li", [
              _c("b", [
                _vm._v(_vm._s(_vm.$untranslated("glossaryItem4Object"))),
              ]),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$untranslated("glossaryItem4")) +
                  "\n        "
              ),
            ]),
            _c("li", [
              _c("b", [
                _vm._v(_vm._s(_vm.$untranslated("glossaryItem5Restriction"))),
              ]),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$untranslated("glossaryItem5")) +
                  "\n          "
              ),
              _c("ul", [
                _c("li", [
                  _vm._v(_vm._s(_vm.$untranslated("glossarySubItem1"))),
                ]),
                _c("li", [
                  _vm._v(_vm._s(_vm.$untranslated("glossarySubItem2"))),
                ]),
                _c("li", [
                  _vm._v(_vm._s(_vm.$untranslated("glossarySubItem3"))),
                ]),
                _c("li", [
                  _vm._v(_vm._s(_vm.$untranslated("glossarySubItem4"))),
                ]),
              ]),
            ]),
            _c("li", [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$untranslated("glossaryItem6RequestTransfer"))
                ),
              ]),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$untranslated("glossaryItem6")) +
                  "\n        "
              ),
            ]),
            _c("li", [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$untranslated("glossaryItem7WithdrawConsent"))
                ),
              ]),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$untranslated("glossaryItem7")) +
                  "\n        "
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }