var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DropdownWrapper", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var attach = ref.attach
          var menuProps = ref.menuProps
          return [
            _c(
              "VAutocomplete",
              _vm._b(
                {
                  attrs: {
                    items: _vm.options,
                    label: _vm.label || _vm.$tr("locationLabel"),
                    multiple: _vm.multiple,
                    box: _vm.box,
                    "item-value": "id",
                    "item-text": "name",
                    required: _vm.required,
                    rules: _vm.rules,
                    "search-input": _vm.searchInput,
                    "no-data-text": _vm.$tr("noCountriesFound"),
                    chips: _vm.multiple,
                    attach: attach,
                    menuProps: menuProps,
                    clearable: "",
                  },
                  on: {
                    "update:searchInput": function ($event) {
                      _vm.searchInput = $event
                    },
                    "update:search-input": function ($event) {
                      _vm.searchInput = $event
                    },
                    change: function ($event) {
                      _vm.searchInput = ""
                    },
                  },
                  model: {
                    value: _vm.locations,
                    callback: function ($$v) {
                      _vm.locations = $$v
                    },
                    expression: "locations",
                  },
                },
                "VAutocomplete",
                _vm.$attrs,
                false
              )
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }