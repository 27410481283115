var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VTextField", {
    attrs: {
      box: "",
      required: _vm.required,
      rules: _vm.rules,
      label: _vm.label || _vm.$tr("passwordLabel"),
      "validate-on-blur": !_vm.validate,
      type: "password",
    },
    on: {
      keyup: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        ) {
          return null
        }
        _vm.validate = true
      },
      keydown: function ($event) {
        _vm.validate = false
      },
    },
    model: {
      value: _vm.field,
      callback: function ($$v) {
        _vm.field = $$v
      },
      expression: "field",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }