var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VApp",
    [
      _c(
        "VToolbar",
        { attrs: { app: "", dark: "", color: "appBarDark" } },
        [
          _vm.backButton
            ? _c(
                "VBtn",
                { attrs: { flat: "", to: _vm.previousPage, exact: "" } },
                [
                  _c("Icon", { staticClass: "rtl-flip" }, [
                    _vm._v("\n        arrow_back\n      "),
                  ]),
                  _c("span", { staticClass: "back-text" }, [
                    _vm._v(_vm._s(_vm.appBarText)),
                  ]),
                ],
                1
              )
            : [
                _c(
                  "VBtn",
                  {
                    attrs: {
                      icon: "",
                      dark: "",
                      to: _vm.previousPage,
                      exact: "",
                    },
                  },
                  [_c("Icon", [_vm._v("close")])],
                  1
                ),
                _c("VToolbarTitle", [
                  _vm._v("\n        " + _vm._s(_vm.appBarText) + "\n      "),
                ]),
              ],
        ],
        2
      ),
      _c(
        "VContent",
        {
          staticStyle: {
            "background-color": "white",
            overflow: "auto",
            height: "calc(100vh - 64px)",
          },
        },
        [
          _c(
            "div",
            { staticClass: "content-wrapper" },
            [
              _c(
                "PageContainer",
                { staticClass: "content" },
                [_vm._t("default")],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }