var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PoliciesModal",
    {
      attrs: {
        policy: _vm.policy,
        title: _vm.title,
        needsAcceptance: _vm.needsAcceptance,
        "data-test": "tos-modal",
      },
      on: {
        accept: function ($event) {
          return _vm.$emit("accept")
        },
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", { staticClass: "tos-wrapper" }, [
        _c("p", { staticClass: "emphasis" }, [
          _vm._v("\n      " + _vm._s(_vm.$tr("prompt")) + "\n    "),
        ]),
        _c("p", [_vm._v(_vm._s(_vm.$tr("disclaimerP1")))]),
        _c("p", [_vm._v(_vm._s(_vm.$tr("disclaimerP2")))]),
        _c("p", { staticClass: "text-uppercase" }, [
          _vm._v("\n      " + _vm._s(_vm.$tr("disclaimerP3")) + "\n    "),
        ]),
        _c("ol", { staticClass: "mb-4 text-uppercase toc" }, [
          _c("li", [
            _c("a", { attrs: { href: "#definitions" } }, [
              _vm._v(_vm._s(_vm.$tr("definitionsHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#account-terms" } }, [
              _vm._v(_vm._s(_vm.$tr("accountTermsHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#acceptable-use" } }, [
              _vm._v(_vm._s(_vm.$tr("acceptableUseHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#user-content" } }, [
              _vm._v(_vm._s(_vm.$tr("userContentHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#licensing" } }, [
              _vm._v(_vm._s(_vm.$tr("licensingHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#dmca" } }, [
              _vm._v(_vm._s(_vm.$tr("dmcaHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#intellectual-property" } }, [
              _vm._v(_vm._s(_vm.$tr("intellectualPropertyHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#community-standards" } }, [
              _vm._v(_vm._s(_vm.$tr("communityStandardsHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#privacy" } }, [
              _vm._v(_vm._s(_vm.$tr("yourPrivacyHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#cancellation" } }, [
              _vm._v(_vm._s(_vm.$tr("cancellationHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#communications" } }, [
              _vm._v(_vm._s(_vm.$tr("communicationsHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#warranties" } }, [
              _vm._v(_vm._s(_vm.$tr("warrantyHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#changes" } }, [
              _vm._v(_vm._s(_vm.$tr("changesToToSHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#jurisdiction" } }, [
              _vm._v(_vm._s(_vm.$tr("jurisdictionHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#arbitration" } }, [
              _vm._v(_vm._s(_vm.$tr("arbitrationHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#liability" } }, [
              _vm._v(_vm._s(_vm.$tr("liabilityHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#third-party" } }, [
              _vm._v(_vm._s(_vm.$tr("thirdPartyHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#third-party-rights" } }, [
              _vm._v(_vm._s(_vm.$tr("thirdPartyRightsHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#indemnification" } }, [
              _vm._v(_vm._s(_vm.$tr("indemnificationHeader"))),
            ]),
          ]),
          _c("li", [
            _c("a", { attrs: { href: "#miscellaneous" } }, [
              _vm._v(_vm._s(_vm.$tr("miscellaneousHeader"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "definitions" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("definitionsHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("definitionsP1")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("definitionsP2")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("definitionsP3")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("definitionsP4")))]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "account-terms" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("accountTermsHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("accountTermsP1")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("accountTermsP2")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("accountTermsP3")))]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "acceptable-use" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("acceptableUseHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("acceptableUseP1")))]),
          _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.$tr("acceptableUseItem1")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("acceptableUseItem2")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("acceptableUseItem3")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("acceptableUseItem4")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("acceptableUseItem5")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("acceptableUseItem6")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("acceptableUseItem7")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("acceptableUseItem8")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("acceptableUseItem9")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("acceptableUseItem10")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("acceptableUseItem11")))]),
          ]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "user-content" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("userContentHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("userContentP1")))]),
          _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.$tr("userContentList1Item1")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("userContentList1Item2")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("userContentList1Item3")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("userContentList1Item4")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("userContentList1Item5")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("userContentList1Item6")))]),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("userContentP2")))]),
          _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.$tr("userContentList2Item1")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("userContentList2Item2")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("userContentList2Item3")))]),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("userContentP3")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("userContentP4")))]),
          _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.$tr("userContentList3Item1")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("userContentList3Item2")))]),
          ]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "licensing" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("licensingHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("licensingP1")))]),
          _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.$tr("licensingList1Item1")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("licensingList1Item2")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("licensingList1Item3")))]),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("licensingP2")))]),
          _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.$tr("licensingList2Item1")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("licensingList2Item2")))]),
          ]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "dmca" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("dmcaHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("dmcaP1")))]),
          _c(
            "p",
            [
              _c("ActionLink", {
                attrs: {
                  text: _vm.$tr("dmcaLink"),
                  href: "https://docs.google.com/forms/d/e/1FAIpQLSd7qWORCOOczCnOlDzaftIjBsaUtl3DKH3hbxlO1arRc1_IQg/viewform?usp=sf_link",
                  target: "_blank",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "section", attrs: { id: "intellectual-property" } },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$tr("intellectualPropertyHeader")))]),
            _c("p", [_vm._v(_vm._s(_vm.$tr("intellectualPropertyP1")))]),
          ]
        ),
        _c(
          "div",
          { staticClass: "section", attrs: { id: "community-standards" } },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$tr("communityStandardsHeader")))]),
            _c("p", [_vm._v(_vm._s(_vm.$tr("communityStandardsP1")))]),
            _c(
              "p",
              [
                _c("ActionLink", {
                  attrs: { text: _vm.$tr("communityStandardsLink") },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("open", _vm.policies.COMMUNITY_STANDARDS)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "section", attrs: { id: "privacy" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("yourPrivacyHeader")))]),
          _c("p", [_vm._v(_vm._s(_vm.$tr("yourPrivacyP1")))]),
          _c(
            "p",
            [
              _c("ActionLink", {
                attrs: { text: _vm.$tr("yourPrivacyLink") },
                on: {
                  click: function ($event) {
                    return _vm.$emit("open", _vm.policies.PRIVACY)
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "cancellation" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("cancellationHeader")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$tr("cancellationP1")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$tr("cancellationP2")))]),
          _c("ul", [
            _c("li", [_vm._v(_vm._s(_vm.$tr("cancellationItem1")))]),
            _c("li", [_vm._v(_vm._s(_vm.$tr("cancellationItem2")))]),
          ]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$tr("cancellationP3")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$tr("cancellationP4")))]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "communications" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("communicationsHeader")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$tr("communicationsP1")))]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "warranties" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("warrantyHeader")))]),
          _c("p", { staticClass: "text-uppercase" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$tr("warrantyHeaderP1")) + "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "changes" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("changesToToSHeader")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$tr("changesToToSP1")))]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "jurisdiction" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("jurisdictionHeader")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$tr("jurisdictionP1")))]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "arbitration" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("arbitrationHeader")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$tr("arbitrationP1")))]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "liability" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("liabilityHeader")))]),
          _c("p", { staticClass: "text-uppercase" }, [
            _vm._v("\n        " + _vm._s(_vm.$tr("liabilityP1")) + "\n      "),
          ]),
        ]),
        _c("div", { staticClass: "section", attrs: { id: "third-party" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("thirdPartyHeader")))]),
          _c("p", { staticClass: "text-uppercase" }, [
            _vm._v("\n        " + _vm._s(_vm.$tr("thirdPartyP1")) + "\n      "),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "section", attrs: { id: "third-party-rights" } },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$tr("thirdPartyRightsHeader")))]),
            _c("p", [_vm._v(" " + _vm._s(_vm.$tr("thirdPartyRightsP1")))]),
          ]
        ),
        _c(
          "div",
          { staticClass: "section", attrs: { id: "indemnification" } },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$tr("indemnificationHeader")))]),
            _c("p", [_vm._v(" " + _vm._s(_vm.$tr("indemnificationP1")))]),
          ]
        ),
        _c("div", { staticClass: "section", attrs: { id: "miscellaneous" } }, [
          _c("h2", [_vm._v(_vm._s(_vm.$tr("miscellaneousHeader")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$tr("miscellaneousP1")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$tr("miscellaneousP2")))]),
          _c("p", [_vm._v(" " + _vm._s(_vm.$tr("miscellaneousP3")))]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }